
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import type { IndexActivitySellerPayload, ActivitySeller } from "@/store/modules/activity-seller.store";
import type { IndexSellerPayload, Seller } from "@/store/modules/seller.store";
import type { PublicOffice } from "@/store/modules/public-office.store";
import type { Publication, IndexPublicationPayload } from "@/store/modules/publications.store";
import { bus } from "@/main";
import { IndexReviewPayload, Review } from "@/store/modules/review.store";

@Component({
    components: {
        FormSelect: () => import("@/components/forms/select.vue"),
        ContactHeaderPart: () => import("@/views/seller/parts/contact/contact-header.vue"),
        ActivityHeaderPart: () => import("@/views/seller/parts/activity/activity-header.vue"),
        ActivityPublicationsPart: () => import("@/views/seller/parts/activity/activity-publications.vue"),
        ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
        previewMediaModal: () => import("@/components/modals/preview-media.vue"),
        reviewOfficeModal: () => import("@/components/modals/review-office.vue"),
        SharedSellers: () => import("@/views/seller/parts/shared-sellers.vue"),
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageSeller extends Vue {
    @Getter("public-office/viewing") currentOffice!: PublicOffice;
    @Getter("auth/me") currentAccount!: CurrentAccount;

    @Action("activity/read") readActivity!: ActivityRead;
    @Action("activity-seller/index") indexActivitySellers!: (payload: IndexActivitySellerPayload) => Promise<ActivitySeller[]>;
    @Action("seller/index") indexSellers!: (payload: IndexSellerPayload) => Promise<Seller[]>;
    @Action("seller/clear") clearSellers!: () => Promise<void>;
    @Action("review/index") indexReviews!: (payload: IndexReviewPayload) => Promise<Review[]>;
    @Action("public-office/read") readPublicOffice!: (payload: { office_id?: number; setStyling?: boolean }) => Promise<PublicOffice>;
    @Action("publications/index") indexPublications!: (payload: IndexPublicationPayload) => Promise<Publication[]>;

    loading = true;

    selectedActivity: number | null = null;

    get activityOptions() {
        return this.currentAccount.activities?.map((a) => {
            return {
                label: a.name,
                value: a.id,
            };
        });
    }

    async mounted() {
        this.selectedActivity = Number(this.$route.params.id);

        await this.initPageData();
    }

    async initPageData() {
        this.loading = true;

        const [activity, activitysellers] = await Promise.all([this.readActivity({ id: Number(this.$route.params.id) }), this.indexActivitySellers({ q: { and: { activity_id: Number(this.$route.params.id) } }, per_page: 100 })]);

        await this.readPublicOffice({ office_id: activity.office_id, setStyling: true });

        await this.indexPublications({ q: { and: { activity_id: Number(this.$route.params.id) } } });

        try {
            if (activitysellers.length) {
                await this.indexSellers({ q: { or: { id: activitysellers.map((seller) => seller.seller_id) } } });
            } else {
                this.clearSellers();
            }
        } catch (e) {}

        this.loading = false;

        this.showReviewPrompt(activity);
    }

    showReviewPrompt(activity: Activity) {
        this.indexReviews({ activity_id: activity.id, q: { and: { account_id: this.currentAccount.id } } }).then((reviews) => {
            if (activity && activity.reviews) {
                if (activity.reviews.can_review_after_publication && !reviews.find((r) => r.type === "AFTER_PUBLICATION") && !localStorage.getItem("hubr-hide-review-AFTER_PUBLICATION")) {
                    bus.$emit("show-office-review", { activity_id: activity.id, account_id: this.currentAccount.id, type: "AFTER_PUBLICATION" });
                } else if (activity.reviews.can_review_after_sales_agreement && !reviews.find((r) => r.type === "AFTER_SALES_AGREEMENT") && !localStorage.getItem("hubr-hide-review-AFTER_SALES_AGREEMENT")) {
                    bus.$emit("show-office-review", { activity_id: activity.id, account_id: this.currentAccount.id, type: "AFTER_SALES_AGREEMENT" });
                } else if (activity.reviews.can_review_after_sale && !reviews.find((r) => r.type === "AFTER_SALE") && !localStorage.getItem("hubr-hide-review-AFTER_SALE")) {
                    bus.$emit("show-office-review", { activity_id: activity.id, account_id: this.currentAccount.id, type: "AFTER_SALE" });
                }
            }
        });
    }

    async handleActivityChanged(newValue: number) {
        if (newValue === Number(this.$route.params.id)) {
            return;
        }

        await this.$router.push({ name: "seller-timeline", params: { id: `${newValue}` } });

        window.location.reload();
    }
}
